.app__menuitem {
  width: 100%;
  margin: 1rem;
}

.app__menuitem-head {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.app__menuitem-sub {
  width: 100%;
  margin-top: 0.2rem;
}

.app__menuitem-name {
  flex: 1;
}


.app__menuitem-price {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.app__menuitem-bottom-dash {
  background: var(--color-golden);
  color: var(--color-golden);
  height: 1px;
}

.accordion-button {
  background-color: var(--color-black);
  color: var(--color-golden);
  cursor: pointer;
  padding: 18px;
  width: 100%;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
  border: 1px solid var(--color-golden);
  outline: none;
}

.accordion-button:hover, .accordion-button:hover, .accordion-button.active{
  background-color: var(--color-golden);
  color: var(--color-black);
  border: 1px solid var(--color-black); 
  outline: none;
}

.accordion-button:hover, .accordion-button.active .accordion-button p{

  background-color: var(--color-black);
  color: var(--color-black);
  border: 1px solid var(--color-black); 
} 

.accordion-button:active, .accordion-button:focus, .accordion-button:visited {
  background-color: var(--color-black);
  color: var(--color-golden);
  cursor: pointer;
  padding: 18px;
  width: 100%;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
  border: 1px solid var(--color-golden);
}

.accordion-item {
  background-color: var(--color-black);
  color: var(--color-golden);
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
}

.accordion-item p {
  color: var(--color-golden);
  cursor: pointer;
  outline: none;
  transition: 0.4s;
}

.menu-info {
  max-width: 180px;
  margin: 0 auto;
  margin-bottom: 1rem;
  padding: 0;
}