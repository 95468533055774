.app__specialMenu {
  flex-direction: column;
  background: url(../../assets/rs_menu_bcg.jpg) no-repeat center center/cover;
}

.app__specialMenu-title {
  margin-bottom: 2rem;
  text-align: center;
}

.app__specialMenu-menu {
  width: 100%;
  margin: 2rem 0;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
}

.app__specialMenu-menu_heading {
  font-family: var(--font-base);
  font-weight: 600;
  font-size: 45px;
  line-height: 58.5px;
  letter-spacing: 0.04em;
  color: var(--color-white);
}

.app__specialMenu-menu_wine,
.app__specialMenu-menu_cocktails {
  flex-direction: column;
  justify-content: space-between;
}

.app__specialMenu-menu_img {
  width: 410px;
  margin: 0 2rem;
}

.app__specialMenu-menu_img img {
  width: 100%;
  height: auto;
}

.app__specialMenu_menu_items {
  display: flex;
  flex-direction: column;
  width: 300px;
}

hr {
  background: var(--color-golden);
}

.bg-black {
  background: var(--color-black) !important;
}

@media screen and (min-width: 2000px) {
  .app__specialMenu-menu_img {
    width: 650px;
  }

  .app__specialMenu-menu_img img {
    height: 920px;
  }
}

@media screen and (max-width: 1150px) {
  .app__specialMenu-menu {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .app__specialMenu-menu_img {
    margin: 3rem 0;
  }
}

@media screen and (max-width: 650px) {
  .app__specialMenu-menu_img {
    width: 100%;
  }

  .app__specialMenu-menu_heading {
    font-size: 35px;
    line-height: 48.5px;
  }
}
