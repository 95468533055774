
.carousel-container {
    position: relative;
    min-height: 50vh;
    background: #000000;
    width: 100%;
    font-family: var(--font-base);
    color: var(--color-golden);
}

.carousel-item  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.carousel-inner  h3 {
    color: var(  --color-white);
    font-size: 2rem;
    font-weight: bolder;
    backdrop-filter: blur(3px);
    display: inline-flex;
}

.carousel-inner  p {
    font-size: 1.4rem;
}